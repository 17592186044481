import { DjatokaSource } from '../Djatoka';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { defaults as defaultInteractions, Draw, Modify, Select, never, Snap, MouseWheelZoom,
    DragRotate
} from 'ol/interaction';
import { shiftKeyOnly, click, mouseOnly } from 'ol/events/condition';
import { defaults as defaultControls, Zoom, ScaleLine } from 'ol/control';
import { disable } from 'ol/rotationconstraint';
import { Feature, Collection, Map, View } from 'ol';
import Projection from 'ol/proj/Projection';
import { defaultOrder } from 'ol/renderer/vector';
import { transform as transformProjection } from 'ol/proj';
import { createStringXY } from 'ol/coordinate';
import { Point, MultiPoint } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Style, Icon, Fill, Stroke } from 'ol/style';
import { polyCollection, setDrawing, setHighlight, modifyObject,
    setLoading, save, setMapDirty, setShowPopper, featuresDump, setFeaturesDump, setMapInit } from './layer2Slice';
import { toast } from 'react-toastify';

let micronPerPx = 0.497400 * 4;

function circle_path(cx, cy, r, deg){
    var theta = deg * Math.PI / 180,
        dx = r * Math.cos(theta),
        dy = -r * Math.sin(theta);
    return "M "+cx+" "+cy+"m "+dx+","+dy+"a "+r+","+r+" 0 1,0 "+-2*dx+","+-2*dy+"a "+r+","+r+" 0 1,0 "+2*dx+","+2*dy;
}
let polyStyle = new Style({
    /*
    image: new PolygonStyle({
        fill: new Fill({color: 'rgba(255, 255, 255, 0.1)'}),
        stroke: new Stroke({color: '#319fd3', width: 1})
    }),
    */
    fill: new Fill({color: 'rgba(255, 255, 255, 0.05)'}),
    stroke: new Stroke({color: '#000000', width: 2})
});

let polyStyleCache = {};
let stdPointStyle = new Style({
    image: new CircleStyle({
        radius: 8,
        stroke: new Stroke({color: '#000000', width: 2}),
        fill: new Fill({color: '#ffffff'})
    })
});

class CenterDragRotate extends DragRotate {
    /*
    handleDragEvent(mapBrowserEvent) {
        if (!mouseOnly(mapBrowserEvent)) {
            return;
        }
        var map = mapBrowserEvent.map;
        var view = map.getView();
        if (view.getConstraints().rotation === disable) {
            return;
        }
        var size = map.getSize();
        var offset = mapBrowserEvent.pixel;
        var theta = Math.atan2(size[1] / 2 - offset[1], offset[0] - size[0] / 2);
        if (this.lastAngle_ !== undefined) {
            var delta = theta - this.lastAngle_;
            const meta = map.getLayers().item(0).getSource().getMeta();
            view.adjustRotationInternal(-delta, [meta.width / 2, -meta.height / 2]);
        }
        this.lastAngle_ = theta;
    }
    */
}
export class DoubleMap {
    constructor(opt) {
        this.rft_id1 = null;
        this.rft_id2 = null;
        this.src1_ready = false;
        this.src2_ready = false;
        this.url = window.location.protocol + '//img2.mrosa.org/adore-djatoka/resolver';
        this.offset = [0, 0];
        this.center_x = 0;
        this.center_y = 0;
        //this.last_center_x = null;
        //this.last_center_y = null;
        this.original_center_x = null;
        this.original_center_y = null;
        this.original_resolution = null;
        this.original_rotation = null;
        this.last_rotation = 0;
        this.last_resolution = null;
        this.opacity = opt.opacity || 0.2;
        this.last_opacity = null;
        this.dispatch = opt.dispatch;
        this.addPolygon = opt.addPolygon;
        this.labelSelected = null;
        this.drawing = false;
        this.gamma = 1.0;
        this.section_id = opt.section_id;
        this.autosave = opt.autosave || false;
        this.loaded = false;
        this.hide_vectors = false;
        this._snap = opt.snap || false;
        this._wheel_zoom = opt.wheel_zoom || false;
        this.view2_offset = {
            x: 0,
            y: 0,
            resolution: 1.0,
            rotation: 0,
            _last_x: null,
            _last_y: null,
            _original_x: null,
            _original_y: null,
            _original_resolution: null,
            _original_rotation: 0,
        }
        this.fitted = false;
        window.map = this;
    }
    setRef1(ref1) {
        if (!this.loaded) {
            this.rft_id1 = ref1;
            this.src1 = new DjatokaSource({
                url: this.url,
                image: ref1,
                gamma: 1.0
            })
            this.src1.getImageMetadata(() => {
                this.src1_ready = true;
                this.init();
            });
        }

    }
    setRef2(ref2) {
        if (!this.loaded) {
            this.rft_id1 = ref2;
            this.src2 = new DjatokaSource({
                url: this.url,
                image: ref2,
                gamma: 1.0
            })
            this.src2.getImageMetadata(() => {
                this.src2_ready = true;
                this.init();
            });
        }
    }
    updateView(event, viewRef) {
        let newValue = event.target.get(event.key);
        if (event.key === 'center') {
            this.center_x = newValue[0];
            this.center_y = newValue[1];
            viewRef.adjustCenter([this.center_x - this.last_center_x, this.center_y - this.last_center_y]);
        } else {
            viewRef.set(event.key, newValue);
        }
    }
    getDelta2(deltaX, deltaY, newRotation, rot2) {
        const up = deltaY * Math.cos(-newRotation) + deltaX * Math.sin(-newRotation);
        const left = deltaY * Math.sin(-newRotation) - deltaX * Math.cos(-newRotation);
        const deltaX2 = -up * Math.sin(rot2) - left * Math.cos(-rot2);
        const deltaY2 = +up * Math.cos(rot2) + left * Math.sin(-rot2);
        return [deltaX2, deltaY2];
    }
    updateView2() {
        let newRotation = this.view1.getRotation();
        let newResolution = this.view1.getResolution();

        const newres = (newResolution / this.original_resolution) * this.view2_offset.resolution * this.view2_offset._original_resolution;
        this.view2.setResolution(newres);

        this.view2.setRotation(newRotation + this.view2_offset.rotation - this.view2_offset._original_rotation);
        //console.log('up', up, 'left', left);
        const rot2 = this.view2.getRotation();

        let newCenter = this.view1.getCenter();
        this.center_x = newCenter[0];
        this.center_y = newCenter[1];
        const deltaX = newCenter[0] - this.original_center_x;
        const deltaY = newCenter[1] - this.original_center_y;
        const [deltaX2, deltaY2] = this.getDelta2(deltaX, deltaY, newRotation, rot2);
        let ratio = newres / newResolution;
        console.log('ratio is', ratio);
        //console.log('deltaX', deltaX, deltaX2);
        //console.log('deltaY', deltaY, deltaY2);
        // need to calculate the offset again from the angle difference
        //let newCenter2 =
        this.view2.setCenter([
            deltaX2 * ratio + this.view2_offset.x + this.view2_offset._original_x,
            deltaY2 * ratio + this.view2_offset.y + this.view2_offset._original_y
        ]);
    }
    updateOffset() {
        //const transform = transformProjection([this.center_x, this.center_y], this.view1.getProjection(), this.view2.getProjection());
        let center = [this.center_x + this.offset[0], this.center_y + this.offset[1]];
        //console.log('move center to', center);
        this.view2.set('center', center);
    }

    polyStyleFunc(feature, resolution) {
        let type = feature.getGeometry().getType();
        if (type === 'Point') {
            return stdPointStyle;
        } else if (type === 'Polygon') {
            let { app } = window;
            let opacity;
            if (app) {
                opacity = app.map.opacity;
            } else {
                opacity = 0.2;
            }
            let pointStyle = new Style({
                image: new CircleStyle({
                    radius: app.map.hideCircle ? 0 : 5,
                    stroke: new Stroke({color: '#000000', width: 2}),
                    fill: new Fill({color: '#ffffff'})
                }),
                geometry: f => {
                    let coords = f.getGeometry().getCoordinates();
                    if (coords) {
                        if (coords.length === 1) {
                            return new MultiPoint(coords[0]);
                        } else {
                            return new MultiPoint(coords[0].concat(coords[1]));
                        }
                    } else {
                        return null;
                    }

                }
            });
            //console.log('getting style for', feature);
            let key = 'poly';
            let rgb = feature.get('rgb');
            /*
                if (that.state.visible[stroke] === false) {
                    return null;
                }
            */
            key = key + '-' + resolution + '-' + rgb + '-' + opacity.toFixed(2);
            const highlight = feature.get('highlight') || feature.get('modifying');
            if (highlight) {
                key += '-h';
            }
            let style_;
            if (polyStyleCache[key]) {
                style_ = polyStyleCache[key];
            } else {
                style_ = polyStyle.clone();
                /*
                const radius = r / resolution;
                   img = style.getImage();
                   img.setRadius(r);
                   style.setStroke('#00ffff');
                   */
                let fill;
                let stroke;
                if (highlight) {
                    stroke = new Stroke({color: feature.get('stroke'), width: 4});
                    if (feature.get('opacity') == 0) {
                        fill = null;
                    } else {
                        fill = new Fill({color: 'rgba(' + feature.get('rgb') + ', 0)'});
                    }
                } else {
                    stroke = new Stroke({color: feature.get('stroke'), width: 1});
                    if (feature.get('opacity') == 0) {
                        fill = null;
                    } else {
                        fill = new Fill({color: 'rgba(' + feature.get('rgb') + ', ' + opacity + ')'});
                    }
                }
                style_.setFill(fill);
                style_.setStroke(stroke);

                let img = new CircleStyle({
                    fill: fill,
                    stroke: stroke
                });
                //style_.setImage(img);

                polyStyleCache[key] = style_;
            }
            return [style_, pointStyle];
        } else if (type === 'LineString') {
            let pointStyle = new Style({
                image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({color: '#800000', width: 2}),
                    fill: new Fill({color: '#ffffff'})
                }),
                geometry: f => {
                    let coords = f.getGeometry().getCoordinates();
                    if (coords) {
                        return new MultiPoint(coords[0]);
                    } else {
                        return null;
                    }

                }
            });
            //console.log('getting style for', feature);
            let key = 'line';
            let rgb = feature.get('stroke');
            /*
                if (that.state.visible[stroke] === false) {
                    return null;
                }
            */
            key = key + '-' + resolution + '-' + rgb;
            const highlight = feature.get('highlight');
            if (highlight) {
                key += '-h';
            }
            let style_;
            if (polyStyleCache[key]) {
                style_ = polyStyleCache[key];
            } else {
                style_ = polyStyle.clone();
                /*
                const radius = r / resolution;
                   img = style.getImage();
                   img.setRadius(r);
                   style.setStroke('#00ffff');
                   */
                let fill;
                let stroke;
                if (highlight) {
                    stroke = new Stroke({color: `rgba(${feature.get('stroke')}, 1)`, width: 6});
                    fill = new Fill({color: 'rgba(' + feature.get('rgb') + ', 0)'});
                } else {
                    stroke = new Stroke({color: `rgba(${feature.get('stroke')}, 1)`, width: 2});
                    fill = new Fill({color: 'rgba(' + feature.get('rgb') + ', 1)'});
                }
                style_.setFill(fill);
                style_.setStroke(stroke);

                let img = new CircleStyle({
                    fill: fill,
                    stroke: stroke
                });
                //style_ = [
                //    style_,
                //];
                //style_.setImage(img);

                polyStyleCache[key] = style_;
            }
            return [style_, pointStyle];
        }
    };


    init() {
        if (this.src1_ready && this.src2_ready) {
            this.loaded = true;
            const meta1 = this.src1.getMeta();
            const meta2 = this.src2.getMeta();
            this.meta1 = meta1;
            this.meta2 = meta2;
            let w = meta1.width;
            let h = meta1.height;
            const proj1 = new Projection({
                code: 'DJATOKA',
                units: 'pixels',
                extent: [0, 0, 256 * Math.pow(2, meta1.levels - 1), 256 * Math.pow(2, meta1.levels - 1)],
                getPointResolution: function (resolution, pointer) {
                    return resolution * micronPerPx * 1e-6;
                }
            });
            const proj2 = new Projection({
                code: 'DJATOKA',
                units: 'pixels',
                extent: [0, 0, 256 * Math.pow(2, meta2.levels - 1), 256 * Math.pow(2, meta2.levels - 1)],
                getPointResolution: function (resolution, pointer) {
                    return resolution * micronPerPx * 1e-6;
                }
            });
            let img_layer1 = new TileLayer({
                className: 'histology-layer',
                source: this.src1,
                name: 'Histology1'
            });
            window.app.src1 = this.src1;
            this.img_layer1 = img_layer1;

            let img_layer2 = new TileLayer({
                source: this.src2,
                name: 'Histology2'
            });
            this.img_layer2 = img_layer2;

            let img_center = [w / 2, -h / 2];
            let extent = [-4 * w, -4 * h, 5 * w, 5 * h];
            console.log('extent is', extent);
            let view1 = new View({
                zoom: 3,
                minZoom: -4,
                maxZoom: meta1.levels + 4,
                projection: proj1,
                center: img_center,
                extent: extent,
                smoothExtentConstraint: false,
                constrainRotation: false,
            });
            this.view1 = view1;
            let w2 = meta2.width;
            let h2 = meta2.height;

            let extent2 = [-5 * w2, -4 * h2, 4 * w2, 4 * h2];
            let center2 = [w2 / 2, -h2 / 2];
            let view2 = new View({
                zoom: 3,
                minZoom: -4,
                maxZoom: meta1.levels + 4,
                projection: proj1,
                center: center2,
                extent: extent2,
                smoothExtentConstraint: false,
                constrainRotation: false,
            });
            this.view2_offset._original_x = center2[0];
            this.view2_offset._original_y = center2[1];

            this.view2 = view2;
            window.view2 = view2;
            this.center_x = img_center[0];
            this.center_y = img_center[1];
            this.original_center_x = img_center[0];
            //this.last_center_x = this.original_center_x;
            this.original_center_y = img_center[1];
            //this.last_center_y = this.original_center_y;
            this.original_resolution = view1.getResolution();
            this.original_rotation = view1.getRotation();

            let vs1 = new VectorSource({features: polyCollection});
            let vl1 = new VectorLayer({
                source: vs1,
                style: this.polyStyleFunc,
                //opacity: this.opacity,
                renderOrder: (self, other) => {
                    if (self.get('highlight')) {
                        return +Infinity;
                    } else {
                        return defaultOrder(self, other);
                    }
                }
            });
            this.vs1 = vs1;
            this.vl1 = vl1;
            let layers1 = [
                img_layer1,
                vl1,
            ];
            const scaleLineControl = new ScaleLine();
            const icon = new Feature({
                geometry: new Point([0, 0])
            });
            let custom_controls = [
                new Zoom({ delta: 0.1 }),
                scaleLineControl
            ];
            let mouse_zoom = new MouseWheelZoom({ deltaPerZoom: 1 });
            let mouse_zoom2 = new MouseWheelZoom({ deltaPerZoom: 1 });
            mouse_zoom.deltaPerZoom_ = 600;
            mouse_zoom2.deltaPerZoom_ = 600;
            this.mouse_zoom = mouse_zoom;
            if (!this._wheel_zoom) {
                mouse_zoom.setActive(false);
            }
            let rotate1 = new CenterDragRotate({ duration: 0 });
            let rotate2 = new CenterDragRotate({ duration: 0 });
            this.map1 = new Map({
                target: 'canvas1-container',
                layers: layers1,
                view: view1,
                pixelRatio: 1,
                controls: defaultControls({
                    zoom: false,
                    attribution: false
                }).extend(custom_controls),
                interactions: defaultInteractions({
                    shiftDragZoom: true,
                    doubleClickZoom: false,
                    mouseWheelZoom: false,
                    altShiftDragRotate: false,
                }).extend([mouse_zoom, rotate1]),
            });
            let coord = null;
            this.map1.on('pointermove', function(evt) {
                coord = evt.coordinate;
            });
            document.addEventListener('mousedown', function() {
                //console.log('coord', coord);
            });
            /*
            var svg = '<svg width="60" height="60" version="1.1" xmlns="http://www.w3.org/2000/svg">'
                + '<defs><g id="pointer" transform="scale(1)">'
                + '<path d="M5,0 l30,0 M-5,0 l-30,0 M0,-5 l0,-30 M0,5 l 0,30"/>'
                + '<path d="' + circle_path(0, 0, 25, 0) + '"/>'
                + '<path d="M0,0 L0,1 L0,0, L0,-1, L0,0 L1,0 L-1,0z"/>'
                + '</g></defs>'
                + '<use href="%23pointer" x="30" y="30" fill="none" pointer-events="visible" stroke="%23333333" stroke-width="2px"/>'
                + '</svg>';
            let style = new Style({
                image: new Icon({
                    opacity: 1,
                    src: 'data:image/svg+xml;utf8,' + svg,
                    scale: 1
                })
            });
            icon.setStyle(style);
            const vs2 = new VectorSource({
                //features: [icon]
            });
            const vl = new VectorLayer({
                source: vs2
            });
            */
            let layers2 = [
                img_layer2,
                //vl
            ];
            this.map2 = new Map({
                target: 'canvas2-container',
                layers: layers2,
                view: view2,
                pixelRatio: 1,
                interactions: defaultInteractions({
                    shiftDragZoom: true,
                    doubleClickZoom: false,
                    mouseWheelZoom: false,
                    altShiftDragRotate: false,
                }).extend([mouse_zoom2, rotate2]),
            });
            const that = this;

            this.view2_offset._original_resolution = view2.getResolution();
            this.view2_offset._original_rotation = view2.getRotation();
            view2.on('change', (e) => {
                let [x, y] = e.target.getCenter();
                let r = e.target.getResolution();
                let ro = e.target.getRotation();
                const { _original_x, _original_y, _original_resolution, _original_rotation } = this.view2_offset;

                /* process center offsets */
                const [v1_x, v1_y] = this.view1.getCenter();
                console.log('rotation', ro, 'x', x, 'y', y);
                console.log("original_center_x", this.original_center_x, 'my center', x);
                const v1_offset_x = v1_x - this.original_center_x;
                const v1_offset_y = v1_y - this.original_center_y;

                const [rotated_offset_x, rotated_offset_y] = this.getDelta2(v1_offset_x, v1_offset_y, this.view1.getRotation(), ro);

                /* process resolution offset */
                const v1_res = this.view1.getResolution();
                this.view2_offset.resolution = r / _original_resolution / (v1_res / this.original_resolution);
                const ratio = r / this.view1.getResolution();
                this.view2_offset.x = x - _original_x - (rotated_offset_x * ratio);
                this.view2_offset.y = y - _original_y - (rotated_offset_y * ratio);

                /* process rotation offset */
                const v1_rot = this.view1.getRotation();
                this.view2_offset.rotation = ro - _original_rotation - (v1_rot);
            });
            this.modify = new Modify({
                /*
                condition: evt => {
                    console.log('drawing', that.drawing);
                    if (that.drawing) {
                        return false;
                    } else {
                        return click(evt);
                    }
                },
                */
                features: polyCollection,
                deleteCondition: evt => shiftKeyOnly(evt) && click(evt),
            });
            this.modify.on('modifystart', (e) => {
                console.log('e', e.features);
                //that.select.setActive(false);
                that.modifying = true;
                //e.feature.set('modifying', true);
                e.features.forEach(f => f.set('modifying', true));
            });
            this.modify.on('modifyend', async (e) => {
                e.features.forEach(f => f.set('modifying', false));
                //e.feature.set('modifying', false);
                //that.select.setActive(true);
                that.modifying = false;
                await that.dispatch(setMapDirty(true));
                if (that.autosave === true) {
                    await that.dispatch(save(that.section_id));
                    toast.success(
                        'Annotation saved.', {
                            position: 'top-center', autoClose: 5000,
                            draggable: true
                        }
                    );

                }
            });
            this.map1.addInteraction(this.modify);
            //this.setModifyEvents();
            this.map1.on('pointermove', function (e) {
                const map = that.map1;
                if (!that.drawing && !that.modifying) {
                    let features = map.getFeaturesAtPixel(e.pixel, {hitTolerance: 10});
                    if (features.length === 0) {
                        if (!map.get('modifying')) {
                            //dispatch(setHighlight(null));
                            if (map.get('highlight')) {
                                polyCollection.forEach(_f => _f.set('highlight', false));
                                map.set('highlight', false);
                            }
                            //dispatch(setModifyPolygon(null));
                            //dispatch(setModifyVertex(null));
                            modifyObject.polygon = null;
                            modifyObject.vertex = null;
                        }

                    } else {
                        if (features.length > 1) {
                        }
                        let poly, point;
                        features.forEach(f => {
                            if (f.get('name') === 'layer') {
                                map.set('highlight', true);
                                poly = f;
                                if (poly.get('highlight') === false) {
                                    polyCollection.forEach(_f => _f.set('highlight', false));
                                    f.set('highlight', true);
                                    //dispatch(setHighlight(f.ol_uid));
                                }
                            } else if (true) {
                                point = f;
                            }
                        });
                        if (poly && point) {
                            //dispatch(setModifyPolygon(poly));
                            //dispatch(setModifyVertex(point));
                            modifyObject.polygon = poly;
                            if (poly.getGeometry().getCoordinates().findIndex(x => x.every(y => point.getGeometry().getCoordinates().includes(y))) > -1) {
                                console.log('polycoords', poly.getGeometry().getCoordinates(), 'point', point.getGeometry().getCoordinates());
                                modifyObject.vertex = point;
                            } else {
                                console.log('polycoords', poly.getGeometry().getCoordinates(), 'point', point.getGeometry().getCoordinates());
                                modifyObject.vertex = null;
                            }
                            /*
                            let p = point.getGeometry().getCoordinates();
                            let g = poly.getGeometry();
                            let coords = g.getCoordinates();
                            console.log('coords', coords);
                            let newCoords = coords[0].filter(v => !(v.length === 2 && v[0] === v[0] && v[1] === p[1]));
                            //g.setCoordinates([newCoords]);
                            */
                        } else if (poly) {
                            //dispatch(setModifyPolygon(poly));
                            //dispatch(setModifyVertex(null));
                            modifyObject.polygon = poly;
                            modifyObject.vertex = null;
                        }
                    }

                }
            });
            this.map2.on('pointermove', function(evt) {
                coord = evt.coordinate;
                console.log('output coord!!!!!', coord);

            });
            this.setupDraw();
            /*
            this.select = new Select({
                condition: evt => {
                    if (that.drawing) {
                        return false;
                    } else {
                        return
                    }
                }
           } );
            this.select.on('select', e => {
                if (e.deselected.length === 1) {
                    let f = e.deselected[0];
                    if (f.get('name') === 'layer') {
                        f.set('highlight', false);
                        //that.dispatch(setHighlight(null));
                    }
                }
                if (e.selected.length === 1) {
                    let f = e.selected[0];
                    console.log('f', f.get('name'));
                    if (f.get('name') === 'layer') {
                        f.set('highlight', true);
                        //that.dispatch(setHighlight(f.ol_uid));
                    }
                }

            });
            this.map1.addInteraction(this.select);
            */
            this.map1.on('click', e => {
                console.log('current position', e.coordinate);
            });
            this.map1.on('dblclick', e => {
                console.log('pixel', e.pixel, that.map1.getFeaturesAtPixel);
                let features = that.map1.getFeaturesAtPixel(e.pixel, { hitTolerance: 1});
                console.log('double', features, features.length);
                if (features.length > 0 && !that.drawing && !that.modifying) {
                    let f = [];
                    features.forEach(_f => {
                        if (_f.get('name') === 'layer') {
                            f.push({
                                'id': _f.get('id'),
                                'ol_uid': _f.get('ol_uid'),
                                'rgb': _f.get('rgb'),
                                'stroke': _f.get('stroke'),
                                'title': _f.get('title'),
                                'type_id': _f.get('type_id'),
                                'coords': _f.getGeometry().getCoordinates(),
                                'memo': _f.get('memo'),
                            });
                        }
                    });
                    that.dispatch(setFeaturesDump(f));
                    that.dispatch(setShowPopper(true));
                }
            });
            this.dispatch(setLoading(false));
            function saveExtent(map) {
                let view = map.getView();
                let extent = view.calculateExtent(map.getSize());
                localStorage.setItem('last_extent', JSON.stringify(extent));
                localStorage.setItem('last_view', Math.floor(new Date().getTime() / 1000));
            }
            this.map1.on('moveend', e => saveExtent(e.map));
            view1.on('change:resolution', e => {
                if (!this.fitted) {
                    return;
                }
                saveExtent(this.map1);
            });
            view1.on('change', e => {
                if (this.fitted) {
                    this.updateView2();
                }
            });
            let _extent = null;
            try {
                let lastView = localStorage['last_view'];
                let now = Math.floor(new Date().getTime() / 1000);
                if (now - lastView < 3600) {
                    _extent = JSON.parse(localStorage['last_extent']);
                }
                if (_extent) {
                    view1.fit(_extent, this.map1.getSize());
                    const c = view1.getCenter();
                    this.original_center_x = c[0];
                    //this.last_center_x = c[0];
                    this.original_center_y = c[1];
                    //this.last_center_y = c[1];
                    //view2.fit(_extent, this.map2.getSize());
                }
                this.last_resolution = view2.getResolution();
                this.fitted = true;
            } catch (e) {
                console.log('parsing last_extent error', e);
            }

            this.dispatch(setMapInit(true));
        }
    }
    /*
    setModifyEvents() {
        const selectedFeatures = this.select.getFeatures();
        this.select.on('change:active', function() {
            console.log('change:active');
            selectedFeatures.forEach(function(each) {
                selectedFeatures.remove(each);
            });
        });
    }
    */
    setupDraw() {
        console.log('label selected when setup is', this.labelSelected);
        const that = this;
        that.ds = new Style({
            fill: null,
            stroke: new Stroke({
                //color: '#319fd3',
                color: '#ff0000',
                width: 3
            }),
            image: new CircleStyle({
                radius: 3,
                fill: new Fill({
                    color: '#319fd3'
                })
            })
        })
        const draw_polygon = new Draw({
            type: 'Polygon',
            style: that.ds,
        });
        let s = new Style({
            fill: null,
            stroke:
                new Stroke({color: '#000000', width: 1})

        });
        that.drawStyle = s;
        draw_polygon.setActive(false);
        this.map1.interactions.push(draw_polygon);
        draw_polygon.on('drawstart', async e => {
            let f = e.feature;
            f.set('name', 'layer');
            f.set('rgb', that.labelSelected.fill);
            f.set('opacity', that.labelSelected.opacity);
            f.set('stroke', that.labelSelected.stroke);
            f.set('title', that.labelSelected.title);
            f.set('type_id', that.labelSelected.id);
            f.set('ol_uid', f.ol_uid);
            // set necessary feature attributes
            /*
            let s = that.drawStyle.clone();
            s.setFill(new Fill({
                color: 'rgba(' + that.labelSelected.fill + ', 0.1)'
            }));
            */
            f.setStyle(s);
        });
        draw_polygon.on('drawend', async e => {
            let f = e.feature;
            f.setStyle(null);
            const g = f.getGeometry();
            const coords = g.getCoordinates();
            let x = coords[0], y = coords[1];
            that.dispatch(that.addPolygon(f));
            draw_polygon.setActive(false);
            that.endDraw();
            if (that.autosave === true) {
                await that.dispatch(save(that.section_id));
                toast.success(
                    'Annotation saved.', {
                        position: 'top-center', autoClose: 5000,
                        draggable: true
                    }
                );

            }
            /*
            // setDrawing false
            */
        });
        const draw_polyline = new Draw({
            type: 'LineString'
        });
        draw_polyline.setActive(false);
        this.map1.interactions.push(draw_polyline);
        draw_polyline.on('drawstart', async e => {
            that.modify.setActive(false);
            let f = e.feature;
            f.set('name', 'layer');
            f.set('rgb', that.labelSelected.fill);
            f.set('stroke', that.labelSelected.stroke);
            f.set('title', that.labelSelected.title);
            f.set('type_id', that.labelSelected.id);
            // set necessary feature attributes
            f.set('ol_uid', f.ol_uid);
            f.setStyle(that.drawStyle);
        });
        draw_polyline.on('drawend', async e => {
            let f = e.feature;
            f.setStyle(null);
            const g = f.getGeometry();
            const coords = g.getCoordinates();
            let x = coords[0], y = coords[1];
            that.dispatch(that.addPolygon(f));
            draw_polyline.setActive(false);
            that.endDraw();
            if (that.autosave === true) {
                await that.dispatch(save(that.section_id));
                toast.success(
                    'Annotation saved.', {
                        position: 'top-center', autoClose: 5000,
                        draggable: true
                    }
                );

            }
        });
        that.draw_polygon = draw_polygon;
        that.draw_polyline = draw_polyline;
        //
        that.snap = new Snap({
            source: this.vs1,
            pixelTolerance: 8,
        });
        if (!that._snap) {
            that.snap.setActive(false);
        }
        this.map1.interactions.push(that.snap);
    }
    startDraw() {
        if (!this.labelSelected) {
            return;
        }
        this.modify.setActive(false);
        this.dispatch(setDrawing(true));
        this.drawing = true;
        if (this.labelSelected.geometry === 'Polygon') {
            this.draw_polygon.setActive(true);
        } else {
            this.draw_polyline.setActive(true);
        }
    }
    endDraw() {
        this.modify.setActive(true);
        this.dispatch(setDrawing(false));
        window.setTimeout(() => {
            this.drawing = false;
        }, 500);
    }
    selectLabel(label) {
        if (label) {
            this.labelSelected = { ...label };
            let fill = JSON.parse(label.fill);
            let stroke = JSON.parse(label.stroke);
            this.labelSelected.fill = `${fill[0]}, ${fill[1]}, ${fill[2]}`;
            this.labelSelected.stroke = `${stroke[0]}, ${stroke[1]}, ${stroke[2]}`;
            this.labelSelected.opacity = fill[3];
        } else {
            this.labelSelected = label;
        }
    }
    setOpacity(opacity) {
        this.opacity = opacity;
    }
    adjustOpacity(delta) {
        if (delta === null) {
            if (this.last_opacity === null) {
                this.last_opacity = this.opacity;
                this.opacity = 0;
            } else {
                this.opacity = this.last_opacity;
                this.last_opacity = null;
            }
        }
        this.opacity += delta;
        if (this.opacity > 1) {
            this.opacity = 1;
        } else if (this.opacity < 0) {
            this.opacity = 0;
        }
        this.vs1.changed();
    }
    toggleVector() {
        this.hide_vectors = !this.hide_vectors;
        if (this.hide_vectors) {
            this.vl1.setVisible(false);
        } else {
            this.vl1.setVisible(true);
        }
    }
}
