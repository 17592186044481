import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
//import { useForm } from 'react-hook-form'

import './layer.scss';

import Moment from 'moment';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import {
    getLayerCases, selectCJCases, selectWCases, selectCandidateCases,
    getLabels
} from './layerSlice';
import { getOffset } from '../lib';


import * as d3 from 'd3';

let origFilterOpt = createFilterOptions({
    stringify: option => option.case_id
});

export default function () {
    const CJCases = useSelector(selectCJCases);
    const WCases = useSelector(selectWCases);
    const { cases, labels } = useSelector(state => state.layer);
    const candidates = useSelector(selectCandidateCases);
    const dispatch = useDispatch();
    let caseIdRef = useRef(null);
    let [ tooltipCaseId, setTooltipCaseId ] = useState(null);
    //let [ tipCaseID, setTipCaseID ] = useState(null);
    useEffect(() => {
        document.title = 'Cortical Layer Delineation Interface';
        dispatch(getLabels());
        dispatch(getLayerCases());
    }, [dispatch])
    /*
    const handleNewCase = useCallback(e => {
        let caseId = caseIdRef.current.value;
        if (caseId) {
            window.location.href = '/layer/' + caseId.toUpperCase();
        }
    }, []);
    */
    const filterOpt = useCallback((options, { inputValue }) => {
        let subOpt = [ ...options ];
        return origFilterOpt(subOpt, { inputValue });

    }, []);
    const showTooltip = useCallback((case_id, elem) => {
        let func = (e) => {
            setTooltipCaseId(case_id);
            let tip = d3.select('.tooltip');
            let parent_box = d3.select('.main .center-wrap').node().getBoundingClientRect();
            let offset = getOffset(e.target, d3.select('.center-wrap').node());
            let left = e.clientX + 20 - parent_box.left;
            let top = e.clientY - 20 - parent_box.top;
            tip.style('left', left + 'px')
                .style('top', top + 'px')
                .style('display', 'block')

        };
        return func;
    }, []);
    const hideTooltip = useCallback(case_id => {
        let func = (case_id) => {
            setTooltipCaseId(null);
            let tip = d3.select('.tooltip');
            tip.style('display', 'none')
        };
        return func;
    }, []);
    const updateTooltip = useCallback(e => {
        let tip = d3.select('.tooltip');
        let parent_box = d3.select('.main .center-wrap').node().getBoundingClientRect();
        let left = e.clientX + 20 - parent_box.left;
        let top = e.clientY - 20 - parent_box.top;
        tip.style('left', left + 'px')
            .style('top', top + 'px');
    }, []);
    let sections = tooltipCaseId !== null ? cases[tooltipCaseId].sections.length : 0;
    let lastModified = tooltipCaseId !== null ? cases[tooltipCaseId].last_modified : null;
    let lastId;
    let lastTime;
    if (lastModified) {
        let _current = 0;
        for (const [key, value] of Object.entries(lastModified)) {
            let v = new Date(value);
            if (v > _current) {
                lastId = key;
                lastTime = v;
                _current = v;
            }
        }
    }
    if (lastId) {
        let _l = labels.find(_l => _l.id === lastId);
        lastId = _l ? _l.label_map_abbrev : null;
    }
    return (
        <div className="center-wrap">
            <div className="labelmap-case-list">
                <h4>Marmoset Layer Delineation</h4>
                <div className="case-label">Type the case you are looking for</div> <Autocomplete options={candidates} getOptionLabel={option => option.case_id}
                    style={{ width: 160 }}
                    renderInput={params =>
                        <TextField { ... params } variant="outlined" />
                    }
                    renderOption={option =>
                        <div className="label-option">
                            <span key={option.case_id}>
                                {option.case_id}
                            </span>
                        </div>
                    }
                    onChange={(e, value) => {
                        window.location.href = '/layer/' + value.case_id.toUpperCase();
                    }}
                    filterOptions={filterOpt}
                    />
                <br/>
                <br/>
                CJ* cases:<br/>
                <div className="case-list-with-limit">
                {
                    CJCases.length > 0 && CJCases.map(v =>
                        <span key={v.case_id} onMouseEnter={showTooltip(v.case_id, this)} onMouseLeave={hideTooltip(v.case_id)} onMouseMove={updateTooltip}>
                            <a href={'/layer/' + v.case_id}>{v.case_id}</a>
                        </span>
                        ).reduce((prev, curr) =>
                        [ prev, ' ', curr ])
                }
                </div>
                <br />
                <br />
                W* cases:<br/>
                <div className="case-list-with-limit">
                {
                    WCases.length > 0 && WCases.map(v =>
                        <span key={v.case_id} onMouseEnter={showTooltip(v.case_id, this)} onMouseLeave={hideTooltip(v.case_id)} onMouseMove={updateTooltip}>
                            <a href={'/layer/' + v.case_id}>{v.case_id}</a>
                        </span>
                        ).reduce((prev, curr) =>
                        [ prev, ' ', curr ])
                }
                </div>
                <br />
                <br />
                <div className="tooltip label-list">
                    <div className="case-info">
                        Case: {tooltipCaseId}<br/>
                        Sections Annotated: {sections}<br/>
                        Last Modified: {Moment(lastTime).format('YYYY-MM-DD HH:mm:ss')}<br/>
                    </div>
                </div>
            </div>
        </div>
    );
}
