import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCases, selectCases, actionCreateThumb } from './cropSlice';
import styles from './Crop.module.scss';
import classNames from 'classnames';
import { Th } from './utils';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ProcessingQueue from './ProcessingQueue';
/*
function Th(props) {
    const { order, orderBy, name, onSortUpdate, children } = props;
    return (
        <th
            className={classNames({
                [styles.up]: order === 'asc' && orderBy === name,
                [styles.down]: order === 'desc' && orderBy === name
            })}
            onClick={onSortUpdate}
            data-order-by={name}
            >{children}</th>
    )
}
*/
export function CropListItem() {
    return (
        <div className="case">
        </div>
    );
}

function CropItem(props) {
    const dispatch = useDispatch();
    const { case_id, last_check, total, pending, memo, locked, archived } = props;
    const [detail, setDetail] = useState(false);
    const onToggleDetail = () => {
        if (detail) {
            setDetail(false);
        } else {
            setDetail(true);
        }
    };
    const [isLocked, setIsLocked] = useState(locked);
    let staining;
    if (case_id.includes('_')) {
        staining = case_id.split('_')[case_id.split('_').length - 1];
    } else {
        staining = 'N/A';
    }
    const onCreateClicked = () => {
        dispatch(actionCreateThumb(case_id));
    }
    return (
        <tr className={styles.row}>
            <td className={classNames(styles.cozy, styles.case_id)}>
                <a href={'/crop/' + case_id} >
                    {case_id} {archived && <FontAwesomeIcon icon={faEyeSlash} size="sm" />}

                </a>
                &nbsp;
                { isLocked ? <FontAwesomeIcon icon={faLock} size="sm"/> : '' }
            </td>
            <td className={styles.cozy}>
                {staining}
            </td>
            <td className={styles.cozy}>
                {total - pending} / {total}
            </td>
            <td className={styles.cozy}>
                {moment(last_check).calendar()}
            </td>
            <td className={classNames(styles.memo, {[styles.brief]: !detail, [styles.detail]: detail})} onClick={onToggleDetail}>
                {
                    memo && (Object.entries(memo).map(it => <div key={it[0]} dangerouslySetInnerHTML={{__html: it[1].join('<br/>')}}></div>))
                }
            </td>
        </tr>
    )
}
export function CropList() {
    const [orderBy, setOrderBy] = useState('case_id');
    const [order, setOrder] = useState('asc');
    const [showArchived, setShowArchived] = useState(false);
    const cases = useSelector(state =>
        selectCases(state, orderBy, order)
    );
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Marmoset Cropping Machine \u00AE V2';
        dispatch(getCases())
    }, [dispatch]);

    const onSortUpdate = e => {
        let field = e.target.dataset.orderBy;
        if (field === orderBy) {
            if (order === 'asc') {
                setOrder('desc');
            } else {
                setOrder('asc');
            }
        } else {
            setOrderBy(field);
            setOrder('desc');
        }
    }
    const [filterId, setFilterId] = useState('');
    const [filterStaining, setFilterStaining] = useState('');
    const [filterMemo, setFilterMemo] = useState('');
    const p = { order, orderBy, onSortUpdate };
    const onFilterIdChange = (e) => {
        setFilterId(e.target.value);
    }
    const onClearFilterId = () => setFilterId('');

    const onFilterStainingChange = (e) => {
        setFilterStaining(e.target.value);
    }
    const onFilterMemoChange = (e) => {
        setFilterMemo(e.target.value);
    }
    const onClearFilterMemo = () => setFilterMemo('');
    return (
        <>
        <ProcessingQueue />
        <table className={styles.list}>
            <thead>
                <tr className={styles.header}>
                    <Th name="case_id" {...p}>Case ID</Th>
                    <Th name="staining" {...p}>Staining</Th>
                    <Th name="progress" {...p}>Progress</Th>
                    <th>Last check</th>
                    <Th name="memo">Memo</Th>
                </tr>
                <tr className={styles.filters}>
                    <th className={styles.filter}><input type="text" value={filterId} onChange={onFilterIdChange} />
                        {filterId !== '' && <span className={styles.filterClear} onClick={onClearFilterId}>X</span>}
                    </th>
                    <th className={styles.filter}>
                        <select value={filterStaining} onChange={onFilterStainingChange}>
                            <option value=""></option>
                            <option value="N">N</option>
                            <option value="M">M</option>
                            <option value="NEUN">NEUN</option>
                            <option value="CO">CO</option>
                            <option value="CalB">CalB</option>
                            <option value="CalR">CalR</option>
                            <option value="PV">PV</option>
                            <option value="others">Others</option>
                        </select>
                    </th>
                    <th></th>
                    <th></th>
                    <th>
                        <input type="text" value={filterMemo} onChange={onFilterMemoChange} />
                        {filterId !== '' && <span className={styles.filterClear} onClick={onClearFilterMemo}>X</span>}
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    cases.filter(v => {
                        /*
                        if (filterId.length === 0) {
                            return true;
                        } else {
                            if (v.case_id.includes(filterId)) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                        */
                        if (filterId.length > 0) {
                            let exp = RegExp(filterId, 'i');
                            if (!exp.test(v.case_id)) {
                                return false;
                            }
                        } else {
                            if (!showArchived && v.archived) {
                                return false;
                            }
                        }
                        if (filterStaining !== '') {
                            switch (filterStaining) {
                                case 'N':
                                    if (
                                        ! /_N$/.test(v.case_id) &&
                                        ! /_Nissl$/.test(v.case_id)
                                    ) {
                                        return false;
                                    }
                                    break;
                                case 'M':
                                    if (
                                        ! /_M$/.test(v.case_id) &&
                                        ! /_Myelin$/.test(v.case_id)
                                    ) {
                                        return false;
                                    }
                                    break;
                                case 'NEUN':
                                    if (
                                        ! /_NEUN$/i.test(v.case_id)
                                    ) {
                                        return false;
                                    }
                                    break;
                                case 'CO':
                                    if (
                                        ! /_CO$/.test(v.case_id)
                                    ) {
                                        return false;
                                    }
                                    break;
                                case 'CalB':
                                    if (
                                        ! /_CalB$/.test(v.case_id) &&
                                        ! /_CB$/.test(v.case_id)
                                    ) {
                                        return false;
                                    }
                                    break;
                                case 'CalR':
                                    if (
                                        ! /_CalR$/.test(v.case_id)
                                    ) {
                                        return false;
                                    }
                                    break;
                                case 'PV':
                                    if (
                                        ! /_PV$/.test(v.case_id)
                                    ) {
                                        return false;
                                    }
                                    break;
                                case 'others':
                                    if (
                                        /_N$/.test(v.case_id) ||
                                        /_Nissl$/.test(v.case_id) ||
                                        /_M$/.test(v.case_id) ||
                                        /_Myelin$/.test(v.case_id) ||
                                        /_NEUN$/i.test(v.case_id) ||
                                        /_CO$/.test(v.case_id) ||
                                        /_CalB$/.test(v.case_id) ||
                                        /_CB$/.test(v.case_id) ||
                                        /_CalR$/.test(v.case_id) ||
                                        /_PV$/.test(v.case_id)
                                    ) {
                                        return false;
                                    }


                                default:
                                    return true;
                                    break;
                            }
                        }
                        if (filterMemo !== '') {
                            let exp = new RegExp(filterMemo, 'i');
                            if (!v.memo) {
                                return false;
                            } else {
                                let res = false;
                                for (let m of Object.values(v.memo)) {
                                    if (exp.test(m)) {
                                        res = true;
                                    }
                                }
                                if (!res) {
                                    return false;
                                }
                            }
                        }
                        return true;
                    }).map(v => <CropItem key={v.id} {...v} />)
                }
            </tbody>
        </table>
        </>
    );
}
