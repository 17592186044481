import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    save, removePolygon, setAutosave,
    setLabelSelected, setSnap, setWheelZoom
} from './layerSlice';
import classNames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';
//import { useForm } from 'react-hook-form'

import { CSSTransition } from 'react-transition-group';

import { TextField, Dialog, DialogContent } from '@material-ui/core';

import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import { updateLabelSectionMemo } from '../../api/API';


//configure({ logLevel: 'debug' });

export default function UtilityPanel(props) {
    const dispatch = useDispatch();
    const {
        labels, mapDirty, hideAll, polylist,
        autosave, drawing, labelSelected, gamma,
        snap, wheel_zoom,
    } = useSelector(state => state.layer);
    const tipRef = useRef();
    const [ showPopper, setShowPopper ] = useState(false);
    const [ showList, setShowList ] = useState(true);
    const { section_id } = useParams();
    const completeRef = useRef(null);
    const label = null;
    /*
    useEffect(() => {
        dispatch(getLabels());
        const listener = e => {
            if (e.key == 'Escape') {
                    //if (context.completeRef.current === document.activeElement) {
                    //context.setLabel(null);
                    //labelRef.current = null;
                    //if (context.completeRef.current) {
                    //    context.completeRef.current.blur();
                    //}
                }
                //context.onDrawStop();

            }
        };
        let handle = window.addEventListener('keyup', listener);
        return () => {
            window.removeEventListener('keyup', listener);
        }
    }, [dispatch]);
    */
    useEffect(() => {
        const map = window.app.map;
        console.log('snap', snap, 'wheel_zoom', wheel_zoom, map);
        if (!map) {
            return;
        }
        if (snap) {
            map.snap.setActive(true);
        } else {
            map.snap.setActive(false);
        }
        if (wheel_zoom) {
            map.mouse_zoom.setActive(true);
        } else {
            map.mouse_zoom.setActive(false);
        }
    }, [snap, wheel_zoom]);
    const startDraw = () => {
        const map = window.app.map;
        map.startDraw();
    }
    const onShowPopper = () => {
        setShowPopper(true);
    };
    const keyMap = {
        SLASH: '/',
        STAR: '*',
        D: 'D+Shift',
        Focus: 'C+Shift',
    };
    const handlers = {
        CLOSE: () => {
            if (showPopper) {
                setShowPopper(false);
            }
        },
        SLASH: e => {
            e.preventDefault();
            const map = window.app.map;
            let value = map.labels.find(v => v.id === 11);
            if (value) {
                dispatch(setLabelSelected(value));
                map.selectLabel(value);
            }
            console.log('drawing', map.drawing);
            if (map.drawing) {
                map.draw_polyline.finishDrawing();
            }
            map.startDraw();
        },
        STAR: e => {
            e.preventDefault();
            const map = window.app.map;
            let value = map.labels.find(v => v.id === 12);
            if (value) {
                dispatch(setLabelSelected(value));
                map.selectLabel(value);
            }
            if (map.drawing) {
                map.draw_polyline.finishDrawing();
            }
            map.startDraw();
        },
        D: e => {
            if (e.key === 'd') {
                console.log('ignore small d');
                // ignore small cased letter
                return true;
            }
            console.log('d pressed', e);
            const map = window.app.map;
            map.startDraw();
        },
        Focus: e => {
            if (e.key === 'c') {
                // ignore small cased letter
                return;
            }
            e.preventDefault();
            completeRef.current.focus()
            completeRef.current.value = '';
        },
    };
    const onSaveClicked = async () => {
        await dispatch(save(section_id));
        toast.success('Annotation saved.', {position: 'top-center', autoClose: 5000, draggable: true});
    };
    const [memoDirty, setMemoDirty] = useState(false);
    const onMemoChanged = async (e) => {
        setMemoDirty(true);

    };
    const onMemoBlurred = async (e) => {
        await updateLabelSectionMemo(section_id, e.target.value);
        setMemoDirty(false);
    };

    const onAutosaveChange = (e) => {
        if (autosave) {
            dispatch(setAutosave(false));
        } else {
            dispatch(setAutosave(true));
        }
    };

    const onWheelZoomChange = (e) => {
        if (wheel_zoom) {
            dispatch(setWheelZoom(false));
        } else {
            dispatch(setWheelZoom(true));
        }
    };

    const onSnapChange = (e) => {
        if (snap) {
            dispatch(setSnap(false));
        } else {
            dispatch(setSnap(true));
        }
    };
    const backClicked = () => {
        window.location.href = '/layer';
    };
    let counts = {};
    polylist.forEach(p => {
        p.properties.title in counts || (counts[p.properties.title] = {'title': p.properties.title, 'count': 0, 'memo': p.properties.memo});
        counts[p.properties.title].count += 1;
    });
    if (hideAll) {
        return <div className="hide-all-overlay">UI is hidden, press Shift + O to reveal.</div>
    } else {
        return <>
            <div className="info-window">

                <div className={classNames(['utility', 'ol-unselectable'])}>
                <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
                <Dialog className="label-meta" open={showPopper} onClose={() => setShowPopper(false)}
                    aria-labelledby="label-meta"
                >
                    <DialogContent>
                        <div className="title">
                            <div className="main-title">
                                Keyboard shortcuts
                            </div>
                            <div className="sub-title">
                                { label &&
                                    <span className="pilot-square" style={{color: `rgb(${label.fill.substring(1, label.fill.length - 1)})`, borderColor: `rgb(${label.stroke.substring(1, label.stroke.length - 1)})`}}>■ </span>
                                }
                            </div>
                        </div>
                        <DialogContentBody/>
                    </DialogContent>
                </Dialog>
                <div className="combo-box-container">
                    <Autocomplete
                        options={labels.filter(v => [11, 12, 7, 13, 14, 15, 16, 17, 18, 19].indexOf(v.id) >= 0)}
                        getOptionLabel={option => option.title}
                        style={{ width: 300 }}
                        value={labelSelected}
                        renderInput={params =>
                            <TextField { ...params }
                                inputRef={ref => completeRef.current = ref}
                                variant="outlined" />
                            }
                        renderOption={option =>
                            <div className="label-option">
                                <span className="pilot-square" style={{
                                    color: `rgb(${option.fill.substring(1, option.fill.length - 1)})`,
                                    borderWidth: `${option.width}px`, borderStyle: 'solid',
                                    borderColor: `rgb(${option.stroke.substring(1, option.stroke.length - 1)})`}}>■ </span>
                                <span>
                                    {option.title}
                                </span>
                            </div>
                        }
                        onChange={(e, value) => {
                            const map = window.app.map;
                            dispatch(setLabelSelected(value));
                            map.selectLabel(value);
                            startDraw();
                        }}
                        blurOnSelect={true}
                        filterOptions={createFilterOptions({
                            stringify: option => option.title
                        })}
                        getOptionSelected={(option, value) => option.id === value?.id}
                        />
                    { true &&
                        <div className="combo-info">
                            <div ref={tipRef} data-tip="tooltip" data-iscapture="true"
                                className="tip"
                                onClick={onShowPopper}
                                > ? </div>
                            <button className="back" onClick={backClicked}>Back</button>
                        </div>
                    }
                </div>
                <div className="control">
                    <button className={classNames('btn-start', 'action', {active: drawing})}
                        id="ol-delineation-button"
                        disabled={!labelSelected}
                        onClick={startDraw}>Draw</button>
                    <button className={classNames('btn-save', 'action', {warn: mapDirty})} id="ol-delineation-save" onClick={onSaveClicked}>Save</button>
                    <button className={classNames('btn-expand', 'action', {expanded: showList, collapsed: !showList})} onClick={() => {
                        if (showList) {
                            setShowList(false);
                        } else {
                            setShowList(true);
                        }
                        }}><span className="btn-toggle-meta">&#x2304;</span></button>
                    Gamma: z | {gamma.toFixed(1)} | c
                    <div className="autosave">
                        <input type="checkbox" name="autosave" id="autosave" onChange={onAutosaveChange} checked={autosave} value="autosave" /> <label htmlFor="autosave">Autosave</label>
                    </div>
                    <div className="snap">
                        <input type="checkbox" name="snap" id="snap" onChange={onSnapChange} checked={snap} value="snap" /> <label htmlFor="snap">Snap</label>
                    </div>
                    <div className="wheel-zoom">
                        <input type="checkbox" name="wheel_zoom" id="wheel_zoom" onChange={onWheelZoomChange} checked={wheel_zoom} value="wheel_zoom" /> <label htmlFor="wheel_zoom">Zoom</label>
                    </div>

                        <CSSTransition
                            in={showList} timeout={200} classNames="label-list"
                            unmountOnExit >
                            <div className="delineation-list-container" >
                                <table className="delineation-list">
                                    <thead>
                                        <tr>
                                            <th>Type</th><th>Segment count</th><th>Memo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(counts).length > 0 ?
                                            Object.keys(counts).map(k =>
                                                <tr key={counts[k].title}>
                                                    <td>{counts[k].title}</td>
                                                    <td>{counts[k].count}</td>
                                                    <td>{counts[k].memo}</td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td></td><td></td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </CSSTransition>
                </div>
            </div>
        </div>
        { /*
        <div className="section-memo">
            <textarea
                placeholder="Per section memo"
                defaultValue={info?.memo}
                className={classNames({dirty: memoDirty})}
                onChange={onMemoChanged}
                onBlur={onMemoBlurred}
                ></textarea>
        </div>
        */}
        </>
    }
}

function DialogContentBody() {
    return (
        <div className="content-container">
            <div className="caption">
            </div>
            <div className="list">
                Basic usage
                <ul>
                    <li>Click Combox box and start typing to search for a layer delineation type</li>
                    <li>Click the type to start drawing. After the label is selected, the drawing mode is enabled, if it is not desired press <kbd>Esc</kbd>.</li>
                    <li>Click on the histology to start plotting the first vertex, and followed by a few vertices</li>
                    <li>Double click the last vertex to finish the drawing. If the last vertex is already determined by last click, press <kbd>Enter</kbd> to finish without adding additional vertex.</li>
                    <li>Press <kbd>Esc</kbd> key to abandon the current drawing.</li>
                    <li>If the page has user modifications, the Save button turns yellow. Click save to preserve the changes.</li>
                    <li>If Auto save is enabled, all modifications will trigger save action.</li>
                    <li>To remove existing line segment, hover the mouse over the line and press <kbd>Del</kbd></li>
                    <li>To remove one vertex from inside a line segment, hover the vertex and while holding <kbd>Shift</kbd>, click the vertex.</li>
                </ul>
                Shortcut keys
                <ul>
                    <li><kbd>Esc</kbd> to cancel current drawing or to clear combox typing.</li>
                    <li><kbd>Shift</kbd> + <kbd>C</kbd>, to jump to the label selection combobox and start searching.</li>
                    <li><kbd>Shift</kbd> + <kbd>S</kbd> or <kbd>Space</kbd> shortcut to save the current drawing.</li>
                    <li><kbd>Shift</kbd> + <kbd>O</kbd>, to hide or show all UI elements.</li>
                    <li><kbd>Shift</kbd> + <kbd>D</kbd>, start drawing when not in drawing mode (after previous line finished).</li>
                    <li><kbd>/</kbd>, start drawing top of Layer IV delineation line right away (after previous line finished)..</li>
                    <li><kbd>*</kbd>, start drawing bottom of Layer IV delineation line.</li>
                    <li><kbd>Del</kbd> Hover over vertex and press Del key to remove the line segment.</li>
                    <li><kbd>-</kbd> Nissl Zoom out</li>
                    <li><kbd>+</kbd> Nissl Zoom in</li>
                    <li><kbd>Right</kbd> Move to work on the next histology section.</li>
                    <li><kbd>Left</kbd> Move to work on the previous histology section.</li>
                    <li><kbd>z</kbd> To reduce gamma correction by 0.1</li>
                    <li><kbd>c</kbd> To increase gamma correction by 0.1</li>
                    <li><kbd>x</kbd> To reset gamma correction to 1.0</li>
                    <li><kbd>a</kbd> To reduce opacity of polygon fill</li>
                    <li><kbd>s</kbd> To temporary toggle opacity of polygon fill</li>
                    <li><kbd>d</kbd> To increase opacity of polygon fill</li>
                    <li><kbd>0</kbd> *Experimental* To toggle display of nissl section</li>
                    <li><kbd>1</kbd> *Experimental* To reduce intensity of nissl overlay</li>
                    <li><kbd>3</kbd> *Experimental* To increase intensity of nissl overlay</li>
                    <li><kbd>2</kbd> <kbd>4</kbd> <kbd>6</kbd> <kbd>8</kbd> *Experimental* To shift nissl section in fine steps</li>
                    <li><kbd>7</kbd> <kbd>9</kbd> *Experimental* To rotate nissl section in fine steps</li>
                </ul>
            </div>
            <div className="note">
                Hit [<strong>Esc</strong>] to close the window.
            </div>
        </div>
    );
}
