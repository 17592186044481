import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import styles from './LabelMap.module.scss';
import classNames from 'classnames';
import { GlobalHotKeys } from 'react-hotkeys';
//import { useForm } from 'react-hook-form'

import 'ol/ol.css';
import './labelmap.scss';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { defaultOrder } from 'ol/renderer/vector';
import { DjatokaSource } from '../Djatoka';
import Projection from 'ol/proj/Projection';
import {OverviewMap, defaults as defaultControls} from 'ol/control';
import { defaults as defaultInteractions, Draw, Modify, Select, MouseWheelZoom } from 'ol/interaction';
import { ScaleLine } from 'ol/control';
import { Map, View } from 'ol';
import { MultiPoint } from 'ol/geom';

import { shiftKeyOnly, click } from 'ol/events/condition';

import UtilityPanel from './UtilityPanel';
import QuickLabelPanel from './QuickLabelPanel';
import SummaryPanel from './SummaryPanel';
import ManagePanel from './ManagePanel';
import APIHelper from './APIHelper';

import { toast } from 'react-toastify';
import {
    LabelContext, addPolygon, polyCollection,
    setHighlight, removeVertex, getSections,
    saveLabelMap, getSectionInfo, setMapDirty,
    modifyObject,
    setHideAll, setAPIHelper, getLocalStorage
} from './labelMapSlice';

let micronPerPx = 0.497400;

/*
function addPolygon(f, collection) {
    //const map = appState.map;
    collection.push(f);
    //map.dirty = true;
}
*/

let polyStyle = new Style({
    /*
    image: new PolygonStyle({
        fill: new Fill({color: 'rgba(255, 255, 255, 0.1)'}),
        stroke: new Stroke({color: '#319fd3', width: 1})
    }),
    */
    fill: new Fill({color: 'rgba(255, 255, 255, 0.05)'}),
    stroke: new Stroke({color: '#000000', width: 2})
});

let polyStyleCache = {};
let stdPointStyle = new Style({
    image: new CircleStyle({
        radius: 8,
        stroke: new Stroke({color: '#000000', width: 2}),
        fill: new Fill({color: '#ffffff'})
    })
});
function polyStyleFunc(feature, resolution) {
    let type = feature.getGeometry().getType();
    const map = window.app.map;
    if (type === 'Point') {
        return stdPointStyle;
    } else if (type === 'Polygon') {
        let pointStyle = new Style({
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({color: '#000000', width: 2}),
                fill: new Fill({color: '#ffffff'})
            }),
            geometry: f => {
                let coords = f.getGeometry().getCoordinates();
                if (coords) {
                    return new MultiPoint(coords[0]);
                } else {
                    return null;
                }

            }
        });
        //console.log('getting style for', feature);
        let key = 'poly';
        let rgb = feature.get('rgb');
        const label_id = feature.get('label_id');
        if (map && map.get('new_only') && label_id <= 85) {
            return null;
        }
        /*
            if (that.state.visible[stroke] === false) {
                return null;
            }
        */
        key = key + '-' + resolution + '-' + rgb;
        const highlight = feature.get('highlight');
        if (highlight) {
            key += '-h';
        }
        let style_;
        if (polyStyleCache[key]) {
            style_ = polyStyleCache[key];
        } else {
            style_ = polyStyle.clone();
            /*
            const radius = r / resolution;
               img = style.getImage();
               img.setRadius(r);
               style.setStroke('#00ffff');
               */
            let fill;
            let stroke;
            if (highlight) {
                stroke = new Stroke({color: feature.get('stroke'), width: 4});
                fill = new Fill({color: 'rgba(' + feature.get('rgb') + ', 0)'});
            } else {
                stroke = new Stroke({color: feature.get('stroke'), width: 1});
                fill = new Fill({color: 'rgba(' + feature.get('rgb') + ', 1)'});
            }
            style_.setFill(fill);
            style_.setStroke(stroke);

            let img = new CircleStyle({
                fill: fill,
                stroke: stroke
            });
            //style_.setImage(img);

            polyStyleCache[key] = style_;
        }
        return [style_, pointStyle];
    }
};

function Thumbnail({section, section_id, case_id, index}) {
    const params = useParams();
    return (
        <a href={section_id == params.section_id ? null : '/label/' + section_id} className={classNames({active: section_id == params.section_id})}>
            <div className="section-thumbnail" id={'thumbnail_' + section_id} data-section-id={section_id}>
                <span className="align-helper"></span>
                <img className="lazy-load bottom-filmstrip" src={'https://cdn.marmosetbrain.org/thumbnails/' + case_id + '/' + section + '.png'} alt={'Section ' + section} data-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYzh8+PB/AAffA0nNPuCLAAAAAElFTkSuQmCC" width="120" height="120"/>
                <div className="section-label">{index} {case_id}-{section}</div>
            </div>
        </a>
    )
}
let lastL;
export default function () {
    const { sections, info, mapDirty, labels, lastLabels, hideAll, wheel_zoom } = useSelector(state => state.labelMap);
    const dispatch = useDispatch();
    let location = useLocation();
    const mapRef = useRef(null);
    const drawRef = useRef(null);
    const dirtyRef = useRef(mapDirty);
    const [ drawing, setDrawing ] = useState(false);
    const olInited = useRef(false);

    //let query = qs.parse(location.search, { ignoreQueryPrefix: true });
    //let q = query.injection.split('-');
    const [ opacity, setOpacity ] = useState(.25);
    const [ label, setLabel ] = useState(null);
    const labelRef = useRef(label);
    const [ showList, setShowList ] = useState(false);
    const [ showPopper, setShowPopper ] = useState(false);
    const [ infoPopper, setInfoPopper ] = useState(null);
    const polyLayerRef = useRef(null);
    const completeRef = useRef(null);
    const collectionRef = useRef(polyCollection);
    const drawingRef = useRef(drawing);
    const { section_id } = useParams();
    const sectionsRef = useRef(null);
    const [ autosave, setAutosave ] = useState(false);
    const autosaveRef = useRef(autosave);
    const hideAllRef = useRef(hideAll);
    const mouseZoomRef = useRef(null);

    function setDraw(map) {
        const draw = new Draw({
            type: 'Polygon'
        });
        draw.setActive(false);
        map.interactions.push(draw);
        draw.on('drawstart', e => {
            let f = e.feature;
            f.set('label_id', labelRef.current.id);
            f.set('rgb', labelRef.current.rgb);
            f.set('title', labelRef.current.title);
            f.set('ol_uid', f.ol_uid);
            f.set('name', 'Label');
            f.set('highlight', false);
            let s = new Style({
                fill: null,
                stroke: new Stroke({color: '#319fd3', width: 3})
            });
            f.setStyle(s);
        });
        draw.on('drawend', async e => {
            let f = e.feature;
            f.setStyle(null);
            const g = f.getGeometry();
            const coords = g.getCoordinates();
            let x = coords[0], y = coords[1];
            dispatch(addPolygon(f));
            drawRef.current.setActive(false);
            setDrawing(false);
            if (autosaveRef.current === true) {
                await dispatch(saveLabelMap(section_id));
                toast.success('Label Map Saved.', {position: 'top-center', autoClose: 5000, draggable: true});

            }
            setTimeout(() => {
                drawingRef.current = false;
            }, 200);
        });
        return draw;
    }
    function setup_map(info) {
        if (olInited.current === false) {
            olInited.current = true;
        } else {
            return;
        }
        let rft_id = info.case_id + '-' + info.nissl_section + '-' + info.staining;
        let url = window.location.protocol + '//img2.mrosa.org/adore-djatoka/resolver';
        let src = new DjatokaSource({
            url: url,
            image: rft_id,
            gamma: 1.0,
        });
        src.getImageMetadata(() => {
            const meta = src.getMeta();
            const imgWidth = meta.width;
            const imgHeight = meta.height;
            const pixelProjection = new Projection({
                code: 'DJATOKA',
                units: 'pixels',
                //extent: [0, 0, imgWidth, -imgHeight]
                extent: [0, 0, 256 * Math.pow(2, meta.levels - 1), 256 * Math.pow(2, meta.levels - 1)],
                getPointResolution: function (resolution, point) {
                    //return resolution / app.res;
                    return resolution * micronPerPx * 1e-6;
                }
            });
            let loading_count = 0;
            src.on('tileloadstart', function() {
                if (loading_count === 0) {
                    /*
                    let indicator = document.getElementById('loading_indicator');
                    indicator.style.color = '#ffa500';
                    indicator.style.backgroundColor = '#ffa500';
                    indicator.innerHTML = 'Loading';
                    */
                }
                loading_count++;
            });
            src.on('tileloadend', function() {
                loading_count--;
                if (loading_count === 0) {
                    /*
                    let indicator = document.getElementById('loading_indicator');
                    indicator.innerHTML = 'Loaded';
                    indicator.style.color = '#008000';
                    indicator.style.backgroundColor = '#008000';
                    */
                }
            });
            let imageLayer = new TileLayer({
                source: src,
                projection: pixelProjection,
                name: 'Histology'
            });
            let imgCenter = [imgWidth / 2, -imgHeight / 2];
            //let imgCenter = [0, 0];
            let extent = [0, -1.5 * imgHeight, 1.5 * imgWidth, 0];
            //let extent;
            {
                let w = imgHeight, h = imgWidth;
                extent = [-0.5 * w, -2 * h, 2 * h, 0.5 * w]
                extent = [-4 * w, -4 * h, 4 * h, 4 * w ]
            }
            let view = new View({
                //zoom: typeof localStorage['last_zoom'] !== 'undefined' ? localStorage['last_zoom'] : 1,
                zoom: 0,
                minZoom: -4,
                maxZoom: meta.levels + 4,
                projection: pixelProjection,
                center: imgCenter,
                extent: extent,
                smoothExtentConstraint: false,
            });
            const scaleLineControl = new ScaleLine();
            const polySource = new VectorSource({features: polyCollection});
            window.app.polySource = polySource;
            let polyLayer = new VectorLayer({
                source: polySource, style: polyStyleFunc, opacity: opacity,
                renderOrder: (self, other) => {
                    if (self.get('highlight') == true) {
                        return +Infinity;
                    } else {
                        return defaultOrder(self, other);
                    }
                }

            });
            polyLayerRef.current = polyLayer;
            let layers = [
                imageLayer,
                polyLayer
            ];
            const modify = new Modify({
                features: polyCollection,
                deleteCondition: evt => shiftKeyOnly(evt) && click(evt)
            });
            const select = new Select({
                condition: evt => {
                    if (drawRef.current.getActive()) {
                        return false;
                    } else {
                        return click(evt);
                    }
                },
                style: polyStyleFunc
            });
            select.on('select', (e) => {
                if (e.deselected.length === 1) {
                    let f = e.deselected[0];
                    if (f.get('name') === 'Label') {
                        f.set('highlight', false);
                        dispatch(setHighlight(null));
                    }
                }
                if (e.selected.length === 1) {
                    let f = e.selected[0];
                    if (f.get('name') === 'Label') {
                        f.set('highlight', true);
                        dispatch(setHighlight(f.ol_uid));
                        //dispatch(setSelectedUid
                        //setShowList(true);
                    }
                }
                /*
                let len = e.target.getFeatures().getLength();
                if (len === 1) {
                } else if (len === 0) {
                    polyCollection.forEach(f => {
                        f.set('highlight', 0);
                    });
                }
                */
            });
            /*
            const select2 = new Select({
                condition: doubleClick,
                style: null
            });
            select2.on('select', e => {
                if (e.selected.length === 1 && !drawRef.current.getActive()) {
                    setShowPopper(true);
                }

            });
            */
            modify.on('modifystart', () => {
                select.setActive(false);
                map.set('modifying', true);
            });
            modify.on('modifyend', async () => {
                select.setActive(true);
                map.set('modifying', false);
                await dispatch(setMapDirty(true));
                if (autosaveRef.current === true) {
                    await dispatch(saveLabelMap(section_id));
                    toast.success('Label Map Saved.', {position: 'top-center', autoClose: 5000, draggable: true});

                }
            });
            const overviewMapControl = new OverviewMap({
                layers: [new TileLayer({
                    source: src,
                    projection: pixelProjection,
                })]
            });
            const map = new Map({
                target: 'canvas-container',
                layers: layers,
                view: view,
                pixelRatio: 1,
                controls: defaultControls().extend([scaleLineControl]),
                interactions: defaultInteractions({
                    shiftDragZoom: false,
                    doubleClickZoom: false,
                    mouseWheelZoom: false,
                })
            });
            map.set('new_only', getLocalStorage('new_only', false));
            mapRef.current = map;
            window.app.map = map;
            drawRef.current = setDraw(map);
            map.interactions.push(modify);
            map.interactions.push(select);

            let mouse_zoom = new MouseWheelZoom({ deltaPerZoom: 1 });
            mouseZoomRef.current =  mouse_zoom;
            mouse_zoom.deltaPerZoom_ = 600;
            if (!wheel_zoom) {
                mouse_zoom.setActive(false);
            }
            map.interactions.push(mouse_zoom);

            map.on('pointermove', function (e) {
                if (!drawRef.current.getActive()) {
                    let features = map.getFeaturesAtPixel(e.pixel, {hitTolerance: 8});
                    if (features.length === 0) {
                        if (!map.get('modifying')) {
                            dispatch(setHighlight(null));
                            if (map.get('highlight')) {
                                polyCollection.forEach(_f => _f.set('highlight', false));
                                map.set('highlight', false);
                            }
                            //dispatch(setModifyPolygon(null));
                            //dispatch(setModifyVertex(null));
                            modifyObject.polygon = null;
                            modifyObject.vertex = null;
                        }

                    } else {
                        if (features.length > 1) {
                            console.log('features', features);
                        }
                        let poly, point;
                        features.forEach(f => {
                            if (f.get('name') === 'Label') {
                                map.set('highlight', true);
                                poly = f;
                                if (poly.get('highlight') === false) {
                                    polyCollection.forEach(_f => _f.set('highlight', false));
                                    f.set('highlight', true);
                                    dispatch(setHighlight(f.ol_uid));
                                    //dispatch(setSelectedUid
                                    //setShowList(true);
                                }
                            } else if (true) {
                                point = f;
                            }
                        });
                        if (poly && point) {
                            //dispatch(setModifyPolygon(poly));
                            //dispatch(setModifyVertex(point));
                            modifyObject.polygon = poly;
                            modifyObject.vertex = point;
                            /*
                            let p = point.getGeometry().getCoordinates();
                            let g = poly.getGeometry();
                            let coords = g.getCoordinates();
                            console.log('coords', coords);
                            let newCoords = coords[0].filter(v => !(v.length === 2 && v[0] === v[0] && v[1] === p[1]));
                            //g.setCoordinates([newCoords]);
                            */
                        } else if (poly) {
                            //dispatch(setModifyPolygon(poly));
                            //dispatch(setModifyVertex(null));
                            modifyObject.polygon = poly;
                            modifyObject.vertex = null;
                        }
                    }

                }
                /*
                if (selected !== null) {
                    selected.setStyle(undefined);
                    selected = null;
                }

                map.forEachFeatureAtPixel(e.pixel, function (f) {
                    selected = f;
                    f.setStyle(highlightStyle);
                    return true;
                });
                */
            });
            map.on('dblclick', function(e) {
                let features = map.getFeaturesAtPixel(e.pixel);
                console.log('drawing', drawingRef.current);
                if (features.length > 0 && !drawingRef.current) {
                    features.forEach(f => {
                        if (f.get('name') === 'Label') {
                            window.hi = labels;

                            let l = labels.find(l => l.id == f.get('label_id'));
                            setInfoPopper({
                                ... f.getProperties(),
                                ... l
                            });
                        }
                    });
                }
            });
            function saveExtent(map) {
                let view = map.getView();
                let extent = view.calculateExtent(map.getSize());
                localStorage['last_extent'] = JSON.stringify(extent);
                localStorage['last_view'] = Math.floor(new Date().getTime() / 1000);
            }
            map.on('moveend', function (e) {
                saveExtent(e.map);
            });
            view.on('change:resolution', function (e) {
                let view = e.target;
                saveExtent(map);
            });
            let _extent = null;
            try {
                let lastView = localStorage['last_view'];
                let now = Math.floor(new Date().getTime() / 1000);
                if (now - lastView < 3600) {
                    _extent = JSON.parse(localStorage['last_extent']);
                }
                if (_extent) {
                    view.fit(_extent, map.getSize());
                }
            } catch (e) {
                console.log('parsing last_extent error', e);
            }
            if (lastLabels.length > 0) {
                let id = lastLabels[lastLabels.length - 1];
                if (id) {
                    let label = labels.find(v => v.id === id);
                    setLabel(label);
                    labelRef.current = label;
                    onStartClicked();
                }
            }
        });

    }

    function adjustOpacity(value, min=0., max=1.)  {
        setOpacity(opacity => {
            let o = opacity;
            if (value === null) {
                // flip the opacity value and set layer to 0;
                if (o > 0) {
                    polyLayerRef.current.setOpacity(0);
                    o = -o;
                } else {
                    polyLayerRef.current.setOpacity(-o);
                    o = -o;
                }
            } else {
                o += value;
                if (o > 1) {
                    o = 1.;
                } else if (o < 0) {
                    o = 0.;
                }
                polyLayerRef.current.setOpacity(o);
            }
            localStorage.setItem('last_opacity', JSON.stringify(o));
            return o;
        });
    }
    const onStartClicked = () => {
        if (labelRef.current) {
            drawRef.current.setActive(true);
            setDrawing(true);
            drawingRef.current = true;
        }
    };
    const onDrawStop = () => {
        drawRef.current.setActive(false);
        setDrawing(false);
        drawingRef.current = false;
    };
    let keyMap = {
        A: 'a',
        S: 's',
        D: 'd',
        O: 'Shift+O',
        START_CLICKED: 'Shift+D',
        FOCUS: 'Shift+C',
        DEL: 'del',
        SAVE: ['0', 'Shift+S', 'Space'],
        ZOOM_IN: '-',
        ZOOM_OUT: '+',
        RIGHT: 'ArrowRight',
        LEFT: 'ArrowLeft',
        L: 'Shift+L'
    }
    let handlers = {
        A: () => {
            adjustOpacity(-0.05);
        },
        S: () => {
            adjustOpacity(null);
        },
        D: () => {
            adjustOpacity(0.05);
        },
        START_CLICKED: onStartClicked,
        FOCUS: (e) => {
            e.preventDefault();
            completeRef.current.focus()
            completeRef.current.value = '';
        },
        DEL: e => {
            //dispatch(removePolygon(-1));

            dispatch(removeVertex());
        },
        SAVE: async e => {
            await dispatch(saveLabelMap(section_id));
            toast.success('Label Map Saved.', {position: 'top-center', autoClose: 5000, draggable: true});
        },
        ZOOM_IN: e => {
            mapRef.current.getView().animate({
                  zoom: mapRef.current.getView().getZoom() - 1,
                    duration: 250
            })
        },
        ZOOM_OUT: e => {
            mapRef.current.getView().animate({
                  zoom: mapRef.current.getView().getZoom() + 1,
                    duration: 250
            })
        },
        RIGHT: e => {
            let idx = sectionsRef.current.findIndex(s => {
                return s.id == section_id;
            });
            if (idx === sectionsRef.current.length - 1) {
                toast('Already the last section of the case', {
                    position: 'top-center', autoClose: 5000, draggable: true
                });
            } else {
                let next = sectionsRef.current[idx + 1];

                window.location.href = '/label/' + next.id;
            }
        },
        LEFT: e => {
            let idx = sectionsRef.current.findIndex(s => {
                return s.id == section_id;
            });
            if (idx === 0) {
                toast('Already the first section of the case', {
                    position: 'top-center', autoClose: 5000, draggable: true
                });
            } else {
                let next = sectionsRef.current[idx - 1];

                window.location.href = '/label/' + next.id;
            }
        },
        O: e => {
            if (hideAllRef.current) {
                dispatch(setHideAll(false));
            } else {
                dispatch(setHideAll(true));
            }
        },
        L: e => {
            if (lastL && (new Date() - lastL) < 1000) {

                let res = window.confirm('You can pressed L twice, do you want to open developer function - API shortcuts?');
                if (res) {
                    dispatch(setAPIHelper(true));
                }
            }
            lastL = new Date();
        }
    }
    useEffect(() => {
        dirtyRef.current = mapDirty;
        autosaveRef.current = autosave;
        hideAllRef.current = hideAll;
    }, [mapDirty, autosave, hideAll]);

    useEffect(() => {
        document.title = 'Label Map Drawing Interface';
        let auto = JSON.parse(localStorage.getItem('last_autosave'));
        if (auto === true) {
            setAutosave(true);
        } else {
            setAutosave(false);
        }
        if (parseInt(section_id, 10) != section_id) {
            fetch('/api/label/case/' + section_id)
            .then(res => {
                if (!res.ok) {
                    alert('No such case ' + section_id + ' in database!');
                    throw Error(res.statusText);
                } else {
                    return res.json();
                }
            })
            .then(data => {
                //history.push(data.url);
                window.location.href = data.url;
            });
            return;
        }
        let o;
        try {
            o = JSON.parse(localStorage['last_opacity']);
        } catch (e) {
            console.log('error parsing last_opacity', e);
        }
        if (o === undefined || o === null) {
            o = 0.25;
        }

        setOpacity(o);
        dispatch(getSectionInfo(section_id));
        dispatch(getSections(section_id));
        window.addEventListener('beforeunload', e => {
            if (dirtyRef.current) {
                e.preventDefault();
                let confirmationMessage = 'There are unsaved changed in this page, ' +
                    'if you leave before saving, your changes will be lost.';
                (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.

            }
        });
    }, [dispatch])

    useEffect(() => {
        if (info && labels) {
            setup_map(info);
        }
    }, [dispatch, info, labels]);

    useEffect(() => {
        if (!mapRef.current) {
            return;
        }
        if (wheel_zoom) {
            console.log('inter', mapRef.current.interactions);
            mouseZoomRef.current.setActive(true);
        } else {
            mouseZoomRef.current.setActive(false);
        }
    }, [wheel_zoom]);
    useEffect(() => {
        if (sections) {
            const film = document.getElementById('filmstrip_container');
            const target = document.getElementById('thumbnail_' + section_id);
            if (target) {
                const left = target.offsetLeft;
                const w = window.innerWidth;
                let l = left - w / 2;
                if (l < 0) {
                    l = 0;
                }
                film.scrollTo({
                    top: 0,
                    left: l,
                    behavior: 'auto'
                });
            }
            sectionsRef.current = sections;
        }
    }, [dispatch, sections]);
    return (
        <LabelContext.Provider value={{drawRef, drawing, setDrawing,
            label, setLabel, labelRef,
            onStartClicked, completeRef,
            onDrawStop,
            collectionRef, showList, setShowList,
            showPopper, setShowPopper,
            infoPopper, setInfoPopper,
            autosave, setAutosave, autosaveRef
        }}>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
            <div className={styles.labelMapContainer}>
                <div className={styles.canvasContainer} id="canvas-container">
                    <UtilityPanel />
                    <QuickLabelPanel />
                    <SummaryPanel />
                    <ManagePanel />
                    <APIHelper />
                </div>
            </div>
            <div className={'filmstrip'} id='filmstrip_container'>
                { sections.map(s => <Thumbnail key={s.id} section={s.code} case_id={s.case_id}
                    section_id={s.id} current_section_id={section_id} index={s.stack_index}
                />) }
            </div>
        </LabelContext.Provider>
    );
}
